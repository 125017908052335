import { useContextBridge } from "drei";
import React, { Suspense } from "react";
import { Canvas } from "react-three-fiber";

import ControlOverlay from "./ControlOverlay";
import Scene from "./Scene";
import { AnimationContext } from "./utils/AnimationContext";
import Controls from "./OrbitControls";
import Lights from "./Lights";
import AudioPlayer from "./AudioPlayer";

export default function Experience() {
  const ContextBridge = useContextBridge(AnimationContext);

  return (
    <div
      className="w-screen h-screen"
      style={{
        backgroundColor: "black",
      }}
    >
      <ControlOverlay />
      <Canvas colorManagement={false} shadowMap>
        <ContextBridge>
          <Suspense fallback={null}>
            <Controls
              enablePan={true}
              enableZoom={true}
              enableDamping
              dampingFactor={0.5}
              rotateSpeed={0.4}
              autoRotate
              autoRotateSpeed={1}
            />
            <Scene />
            <Lights />
            <AudioPlayer />
          </Suspense>
        </ContextBridge>
      </Canvas>
    </div>
  );
}
