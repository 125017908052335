import React, { ReactNode, useContext, useEffect, useState } from "react";
import { PLAYING_I_DURATION } from "../Scene";
import { AnimationState } from "./types";
import useTimeout from "./useTimeout";

type AnimationContextType<T extends string> = [
  state: T,
  setState: React.Dispatch<React.SetStateAction<AnimationState>>
];

export const AnimationContext = React.createContext<
  AnimationContextType<AnimationState>
>(["READY", () => {}]);

export const SceneProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<AnimationState>("READY");
  const [setDelay, cancel] = useTimeout(PLAYING_I_DURATION);
  console.log(state, "(STATE)");

  // useEffect(() => void setTimeout(() => setState("PLAYING_II"), 1000));

  useEffect(() => {
    state === "PLAYING_I" && setDelay(() => setState("PLAYING_II"));
    if (state !== "PLAYING_I") {
      cancel();
    }
  }, [state, setDelay, setState, cancel]);

  return (
    <AnimationContext.Provider value={[state, setState]}>
      {children}
    </AnimationContext.Provider>
  );
};

export const useAnimationContext = () => useContext(AnimationContext);
