import { useEffect, useRef } from "react";
import { useAnimationContext } from "./utils/AnimationContext";

const stop = (a: HTMLAudioElement) => {
  a.pause();
  a.currentTime = 0;
  return a;
};

const AudioPlayer = () => {
  const [animState] = useAnimationContext();
  const audioPlayingRef = useRef<HTMLAudioElement>(
    new Audio(process.env.PUBLIC_URL + "/assets/audio/UCL_Muse_Audio_v2.mp3")
  );
  const audioExternalRef = useRef<HTMLAudioElement>(
    new Audio(process.env.PUBLIC_URL + "/assets/audio/Muse_UCL_out2.mp3")
  );

  useEffect(() => {
    const [playing1, external] = [
      audioPlayingRef.current,
      audioExternalRef.current,
    ];

    external.loop = true;

    if (animState === "PLAYING_II") return;
    animState === "PLAYING_I" ? playing1.play() : stop(playing1);

    // animState === "PLAYING_II" ? playing2.play() : playing2.pause();
    animState === "EXTERNAL" ? external.play() : stop(external);

    // return () => {
    //   stop(playing1);
    //   stop(external);
    // };
  });

  return null;
};

export default AudioPlayer;
