import { useEffect, useRef } from "react";
import { useFrame, useThree, extend, ReactThreeFiber } from "react-three-fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import { useAnimationContext } from "./utils/AnimationContext";
import { PerspectiveCamera } from "three";
import { a, useSpring } from "@react-spring/three";

extend({ OrbitControls });
const Controls = (props: any) => {
  const [animState] = useAnimationContext();
  const { gl, setDefaultCamera } = useThree();
  const cameraRef = useRef<PerspectiveCamera>(null);
  const ref = useRef<any>(null);

  const { spring } = useSpring({
    spring: animState === "EXTERNAL" ? 1 : 0,
    config: { mass: 1, tension: 400, friction: 180, precision: 0.001 },
  });

  useFrame(() => ref.current !== null && ref.current.update());
  useEffect(() => {
    if (cameraRef.current == null) return;
    animState === "EXTERNAL" && setDefaultCamera(cameraRef.current);
  });

  return (
    <group>
      <a.perspectiveCamera
        position-x={spring.to([0, 1], [5, 0])}
        position-y={0}
        position-z={spring.to([0, 1], [4, 8])}
        ref={cameraRef}
      />
      {cameraRef.current && (
        <orbitControls
          enabled={animState === "EXTERNAL"}
          ref={ref}
          args={[cameraRef.current, gl.domElement]}
          {...props}
        />
      )}
    </group>
  );
};

declare global {
  namespace JSX {
    interface IntrinsicElements {
      orbitControls: ReactThreeFiber.Object3DNode<
        OrbitControls,
        typeof OrbitControls
      >;
    }
  }
}

export default Controls;
