import { a, useSpring } from "@react-spring/three";
import { useAnimationContext } from "./utils/AnimationContext";

const Lights = () => {
  const [animState, setAnimState] = useAnimationContext();
  const config = {
    mass: 50,
    tension: 100,
    friction: 50,
    precision: 0.01,
  };

  const { spring } = useSpring({
    spring: animState !== "PLAYING_II" ? 0 : 1,
    config: { ...config, mass: 100 },
  });

  const { fadeSpringB } = useSpring({
    fadeSpringB: animState.includes("TRANSITION") ? 0 : 1,
    config: { ...config, mass: 50 },
    onRest: () => {
      setTimeout(() => {
        switch (animState) {
          case "TRANSITION->EXTERNAL":
            setAnimState("EXTERNAL");
            break;
          case "TRANSITION->PLAYING_I":
            setAnimState("PLAYING_I");
            break;
        }
      }, 500);
    },
  });

  return (
    <a.group>
      <a.hemisphereLight
        color={spring.to([0, 1], ["rgb(0, 173, 247)", "rgb(224,77,105)"])}
        groundColor={
          spring.to([0, 1], ["rgb(223, 245, 255)", "rgb(255, 243, 245)"]) as any
        }
        intensity={fadeSpringB.to([0, 1], [0, 0.8])}
      />
      {/* "rgb(167, 41, 41)" */}
      {/* "rgb(255, 243, 245)" */}

      {/* <a.pointLight
        position={[80, 80, 0]}
        intensity={fadeSpringA.to([0, 1], [0, 0.2])}
        color={spring.to([0, 1], ["#7cd6fc", "#ff3b5f"])}
      />
      <a.pointLight
        position={[-80, -80, 20]}
        intensity={fadeSpringB.to([0, 1], [0, 0.2])}
        color={spring.to([0, 1], ["#672ded", "#cb0963"])}
      /> */}
    </a.group>
  );
};

export default Lights;
