import { useCallback, useEffect, useRef } from "react";

const useTimeout = (delay: number) => {
  const ref = useRef<NodeJS.Timeout>();
  useEffect(() => ref.current && clearTimeout(ref.current));

  const cancel = useCallback(
    () => ref.current && clearTimeout(ref.current),
    []
  );

  const applyTimeout = useCallback(
    (cb: any) => (ref.current = setTimeout(cb, delay)),
    [delay]
  );

  return [applyTimeout, cancel] as [(cb: () => any) => any, () => any];
};

export default useTimeout;
