import { useRef, useEffect, useState } from "react";

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { draco } from "drei";
import { useFrame, useLoader, useThree } from "react-three-fiber";
import { AnimationClip, AnimationMixer } from "three";
import { useAnimationContext } from "./utils/AnimationContext";
import useTimeout from "./utils/useTimeout";

const aorta = "assets/path-3-recieved-7.glb";
// const aorta = "assets/path-3.glb";
export const PLAYING_I_DURATION = 40000;
export const PLAYING_II_DURATION = 24000;

useLoader.preload(GLTFLoader, aorta, draco());
function Aorta() {
  const { setDefaultCamera, camera } = useThree();
  const [animState, setAnimState] = useAnimationContext();
  const group = useRef<any>();
  const [delay, cancel] = useTimeout(PLAYING_II_DURATION);

  const { nodes, animations, scene } = useLoader(
    GLTFLoader,
    aorta,
    draco()
  ) as any;

  // console.log(scene);
  // console.log(nodes);

  const [mixer] = useState(() => new AnimationMixer(scene));

  useFrame((_, delta) => mixer.update(delta * 0.2));
  useEffect(() => {
    nodes.Inside_Orientation.near = 1;
    nodes.Inside_Orientation.far = 100000;
    nodes.Inside_Orientation.updateProjectionMatrix();
    nodes.Inside_Orientation.updateProjectionMatrix();

    console.log("delay starts now");
  }, [scene, nodes.Inside_Orientation]);

  useEffect(() => {
    animState === "PLAYING_II" &&
      delay(() => {
        setAnimState("TRANSITION->EXTERNAL");
        console.log("transitioning to external");
      });

    if (animState !== "PLAYING_II") {
      console.log(animState, "cancelled");
      cancel();
    }
  }, [animState, setAnimState, cancel, delay]);

  useEffect(() => {
    switch (animState) {
      case "READY":
        setDefaultCamera(nodes.Inside_Orientation);
        break;
      case "PLAYING_I":
        setDefaultCamera(nodes.Inside_Orientation);
        animations.forEach((clip: AnimationClip) => {
          mixer.clipAction(clip, group.current).play();
        });
        break;

      case "EXTERNAL":
        animations.forEach((clip: AnimationClip) => {
          mixer.clipAction(clip, group.current).stop();
        });
        break;
    }
    return () => {
      cancel();
      setDefaultCamera(camera);
    };
  }, [
    animations,
    animState,
    camera,
    mixer,
    nodes.Inside_Orientation,
    setDefaultCamera,
    setAnimState,
    cancel,
  ]);

  return (
    <group>
      <primitive ref={group} object={scene} />
    </group>
  );
}

export default Aorta;
