import { useState } from "react";
import { useAnimationContext } from "./utils/AnimationContext";

const ControlOverlay = () => {
  const [animState, setAnimState] = useAnimationContext();
  const [about, setAbout] = useState(false);

  return (
    <div className="absolute z-10 flex w-screen h-screen p-12 overflow-auto text-white pointer-events-none pointer">
      <div className="relative w-full h-full">
        <div className="absolute top-0 left-0 text-xl fadeIn">
          <img src="logo.png" width="320" alt="" />
          <div className="mt-2">The Aorta : The River Within Us</div>
        </div>

        <div
          style={{ width: "32rem" }}
          className="absolute top-0 right-0 text-xl text-right delay-75 border-white pointer-events-auto fadeIn"
        >
          <span
            className="text-right cursor-pointer"
            onClick={() => setAbout(!about)}
          >
            About
          </span>
          {about && (
            <div className="mt-16 text-sm text-justify">
            
              Computer simulations are used everywhere: from the design of cars,
               airplanes and space stations, to the modelling of human cells. 
               We are using them to study blood flow in the aorta, the largest 
               artery in the body, to understand the impact of lethal conditions 
               such as Aortic Dissection that affect the aortic walls. Simulations 
               allow us to examine blood velocity, pressure, and other fluid forces 
               in blood vessels that are difficult or impossible to measure directly 
               in the patient. This information can help us understand more about 
               aortic diseases and how to treat them. ‘The Aorta: The River Within Us’ 
               takes you on an immersive and interactive journey through a virtual aorta, 
               based on real patient data. We hope this experience will illustrate the 
               power of computer simulations and how these technologies can be applied 
               to help patients, as well as raise awareness about the crucial role 
               of the Aorta and blood pressure in our health.

               <div className="logoContainer d-flex flex-sm-row .d-inline-block">
                
                  <a href="https://www.ucl.ac.uk/interventional-surgical-sciences/the-river-within-us">
                    <img className="mt-12" src="logo_weiss2.png" alt="weiss" style={{display: 'inline-block'}}/>
                 </a>
        
                  <a href="https://aorticdissectionawareness.org/"><img className="mt-12" src="logo_ada2.png" alt="ada" style={{display: 'inline-block'}}/>
                  </a>
                
                  <a href="http://www.cellule.co.uk/"><img className="mt-12" src="logo_cellule2.png" alt="cellule" style={{display: 'inline-block'}} />
                  </a>
                
              </div>
            </div>
          )}
        </div>

        <div
          style={{ marginLeft: "-90px" }}
          className="fixed pointer-events-none left-1/2 bottom-1/3"
        >
          <div style={{ width: 180}} className="absolute">
            <img
              className={`transition-opacity duration-500 ${
                animState === "PLAYING_I" ? "opacity-100" : "opacity-0"
              }`}
              src="replacer.png"
              alt=""
            />
          </div>
          <div style={{ width: 180 }} className="absolute">
            <img
              className={`absolute transition-opacity duration-500 ${
                animState === "PLAYING_II" ? "opacity-100" : "opacity-0"
              }`}
              src="replacer.png"
              alt=""
            />
          </div>
        </div>

        {!animState.includes("TRANSITION") && (
          <div
            className="absolute bottom-0 left-0 self-center text-xl delay-75 border-white cursor-pointer pointer-events-auto fadeIn"
            onClick={() => {
              switch (animState) {
                case "READY":
                  setAnimState("PLAYING_I");
                  break;
                case "PLAYING_I":
                case "PLAYING_II":
                case "TRANSITION->PLAYING_I":
                  setAnimState("TRANSITION->EXTERNAL");
                  break;
                case "EXTERNAL":
                  setAnimState("TRANSITION->PLAYING_I");
                  break;
              }
            }}
          >
            {animState.includes("PLAYING") ? "Exit" : "Start"}
          </div>
        )}

        <div className="absolute bottom-0 right-0 self-center text-xl delay-75 border-white cursor-pointer pointer-events-auto text fadeIn">
          <a
            href="https://twitter.com/WEISS_UCL"
            className="ml-4 cursor-pointer"
          >
            Twitter
          </a>
          <a
            href="https://www.ucl.ac.uk/interventional-surgical-sciences/the-river-within-us"
            className="ml-4 cursor-pointer"
          >
            More
          </a>
        </div>
      </div>
    </div>
  );
};

export default ControlOverlay;
