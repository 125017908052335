import React from "react";

import "./styles/tailwind.build.css";
import "./styles/App.css";

import Experience from "./components/Experience";
import { SceneProvider } from "./components/utils/AnimationContext";

function App() {
  return (
    <div className="App">
      <SceneProvider>
        <Experience />
      </SceneProvider>
    </div>
  );
}

export default App;
